import React from "react";
import "./styles.css";
import { Grid } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";

import { Link } from "gatsby"

const date = new Date();
const year = date.getFullYear();

function Footer() {
  return (
    <div
      className="Footer"
      style={{
        maxWidth: "900px",
        border: "auto",
        margin: "auto"
      }}
    >
      <Grid
        container
        direction="row"
        spacing={0}
        justify="center"
        alignItems="center"
        style={{
          fontSize: "0.95rem",
          padding: "10px",
          paddingTop: "0px"
        }}
      >
        <Grid item xs={9}>
          <p style={{ paddingTop: "0px", textAlign: "left" }}>
          <Link to="/about/" style={{ textDecoration: "none", color: "grey" }}>
                Campbell Data Science {year}
              </Link>
          </p>
        </Grid>
        <Grid item xs={3}>
          <p
            style={{
              paddingTop: "0px",
              paddingRight: "5px",
              textAlign: "right"
            }}
          >
          <Link to="/privacy-policy/" style={{ textDecoration: "none", color: "grey" }}>
                Privacy
              </Link>            
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;

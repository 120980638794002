import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    //padding top 1 in-line so it's clear it's an addition
    padding: theme.spacing(3)
  }
}));

// const preventDefault = event => event.preventDefault();

function getSteps() {
  return [
    "Let's get started",
    "Domain name",
    "Web hosting",
    "Link them together"
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <div>
          <p>Get comfortable and click next to begin</p>
          <p>
            Links open in a <strong>new window</strong> so don't worry about
            navigation
          </p>
        </div>
      );
    case 1:
      return (
        <div>
          <p>
            A Domain name is a <strong>unique identifier</strong> for your
            website.
          </p>
          <p>
            Go to{" "}
            <Link
              href="https://www.kqzyfj.com/click-9160314-12892698"
              // onClick={preventDefault}
              target="_blank"
              rel="noopener nofollow"
            >
              namecheap
            </Link>{" "}
            and try out ideas for your website name to see if they are
            available.
          </p>
          <p>
            You might not get what you want on the first try so use{" "}
            <strong>synonyms</strong> or google translate for words in other{" "}
            <strong>languages</strong>, use your imagination.
          </p>
          <p>
            For a personal blog you only need the .com extension. I have bought
            the .co.uk for this domain too because I’m based in the UK and I
            want to protect my business name. It’s up to you.
          </p>
          <p>
            If you are really struggling, try a different extension but I would
            not recommend this for your first site.{" "}
            <strong>Keep it simple</strong>.
          </p>
          <p>
            You don’t need to purchase any optional extras, just ignore them.
          </p>
          <p>
            Namecheap has{" "}
            <Link
              href="https://www.jdoqocy.com/click-9160314-13346132"
              // onClick={preventDefault}
              target="_blank"
              rel="noopener nofollow"
            >
              free privacy protection
            </Link>{" "}
            for .com that keeps the information you used on domain registration
            safe from spammers.
          </p>
          {/* <p>Namecheap review…. Full setup guide….</p> */}
        </div>
      );
    case 2:
      return (
        <div>
          <p>
            Web hosting is <strong>storage space</strong> that allows your site
            to be <strong>accessible</strong> via the internet.
          </p>
          <p>
            Go to{" "}
            <Link
              href="https://www.siteground.com/index.htm?afcode=5c0d3ef0752592ef0a6eee476d89949b"
              // onClick={preventDefault}
              target="_blank"
              rel="noopener nofollow"
            >
              SiteGround
            </Link>{" "}
            and select the <strong>WordPress</strong> hosting plan that suits
            you.
          </p>
          <p>
            Single site; choose StartUp,{" "}
            <Link
              href="https://www.siteground.com/index.htm?afcode=5c0d3ef0752592ef0a6eee476d89949b"
              // onClick={preventDefault}
              target="_blank"
              rel="noopener nofollow"
            >
              3 months for £0.99 right now
            </Link>
          </p>{" "}
          <p>Multiple sites; choose GrowBig</p>
          <p>
            Hosting is like car insurance, when it’s time for renewal, shop
            around so you know if you should stay or switch. It’s easier than
            you might think to change host.
          </p>
          <p>
            Go through the process, input the domain name you already have.
            Select <strong>24 months</strong> at least, if you want to take
            advantage of the introductory discount.
          </p>
          <p>Don’t get any optional paid extras then complete your purchase.</p>
          {/* <p>Siteground review</p> */}
        </div>
      );
    case 3:
      return (
        <div>
          <p>
            Login to{" "}
            <Link
              href="https://www.siteground.com/index.htm?afcode=5c0d3ef0752592ef0a6eee476d89949b"
              // onClick={preventDefault}
              target="_blank"
              rel="noopener nofollow"
            >
              siteground
            </Link>
            , go to <strong>websites</strong> then <strong>cPanel</strong>. You
            should be able to see two
            <strong> name servers</strong> under account information. You will
            need these next.
          </p>
          <p>
            Login to{" "}
            <Link
              href="https://www.kqzyfj.com/click-9160314-12892698"
              // onClick={preventDefault}
              target="_blank"
              rel="noopener nofollow"
            >
              namecheap
            </Link>
            . In your dashboard, select the <strong>manage</strong> button next
            to your new domain name.
          </p>
          <p>
            Under <strong>nameservers</strong>, click the dropdown and select{" "}
            <strong>Custom DNS</strong>. Paste in your two nameservers from
            siteground.
          </p>
          <p>
            Documentation says it can take 48 hours to connect but I have never
            had to wait that long. Well under an hour usually.
          </p>
          <p>
            Back to your siteground cPanel. under{" "}
            <strong>autoinstallers</strong>, click <strong>WordPress</strong>.
            Select <strong>install now</strong> and follow the prompts.
          </p>
        </div>
      );
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography className="Typography">
                {getStepContent(index)}
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper
          square
          elevation={0}
          className={classes.resetContainer}
          style={{ paddingTop: "1px" }}
        >
          <Typography className="Typography">
            <hr />
            <h1
              style={{
                fontSize: "1.5rem"
                // color: "grey"
              }}
            >
              Congratulations, you're all done!
            </h1>
            <p>
              That's the hard bit out of the way, now it's time to make your
              site look good and publish some content.
            </p>
            <h2
              style={{
                fontSize: "1.2rem"
                // color: "grey"
              }}
            >
              Here's a few tips to help you
            </h2>
            <p>
              There are plenty of guides for getting a beautiful wordpress
              layout and lots of <strong>templates</strong> to save time. If you
              want to customise further, google is your best friend and there
              are hundreds of great <strong>plugins</strong>.
            </p>
            <p>
              A <strong>shortcut</strong> to get to your wordpress so you don't
              have to login to siteground every time; type login after your
              domain in the search bar of your browser.. yoursite.com/login
            </p>
            ...wordpress automatically redirects to yoursite.com/wp-login.php
            then you can login.
            <p>
              Use{" "}
              <Link
                href="https://www.zoho.com/mail/"
                // onClick={preventDefault}
                target="_blank"
                rel="noopener nofollow"
              >
                Zoho Mail
              </Link>{" "}
              for <strong>free email setup</strong>, they are very generous with
              their storage and options for their free tier. They have great
              documentation too. Have a professional email linked to your site
              domain; yourname@yoursite.com
            </p>
            <p>
              If you can code in html, css and javascript or know any static
              front end javascript libraries, you can{" "}
              <Link to="/html-website-setup-cpanel/" style={{ textDecoration: "none" }}>
              upload pages
              </Link>
              {" "}
              you have built alongside your siteground wordpress install. You're
              on a site that runs this way right now.
            </p>
            <hr />
            <h2
              style={{
                fontSize: "1.2rem"
                // color: "grey"
              }}
            >
              
              Links to resources mentioned..
            </h2>
            <p>
              <Link
                href="https://www.kqzyfj.com/click-9160314-12892698"
                // onClick={preventDefault}
                target="_blank"
                rel="noopener nofollow"
              >
                Namecheap
              </Link>{" "}
              - domain name
            </p>
            <p>
              <Link
                href="https://www.siteground.com/index.htm?afcode=5c0d3ef0752592ef0a6eee476d89949b"
                // onClick={preventDefault}
                target="_blank"
                rel="noopener nofollow"
              >
                Siteground
              </Link>{" "}
              - web hosting
            </p>
            <p>
              <Link
                href="https://www.zoho.com/mail/"
                // onClick={preventDefault}
                target="_blank"
                rel="noopener nofollow"
              >
                Zoho Mail
              </Link>{" "}
              - email hosting
            </p>
          </Typography>

          <Button onClick={handleReset} className={classes.button}>
            Reset checklist
          </Button>
        </Paper>
      )}
    </div>
  );
}

import React from "react";
import "./styles.css";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  introContainer: {
    padding: theme.spacing()
  }
}));

function Introduction() {
  const classes = useStyles();

  return (
    <div
      className="Header"
      style={{
        maxWidth: "900px",
        border: "auto",
        margin: "auto",
        paddingBottom: "10px"
      }}
    >
      <Paper square elevation={0} className={classes.introContainer}>
        <Grid
          container
          direction="row"
          spacing={0}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "left",
                fontSize: "0.95rem",
                padding: "10px",
                paddingTop: "0px",
                paddingBottom: "10px",
                color: "grey"
              }}
            >
              <h1 style={{ fontSize: "1.5rem", color: "grey" }}>
                Website setup checklist
              </h1>
              <p>
                This is the quickest & easiest way to set up a low cost website
                with a custom domain. These are the essentials you need to get
                your site online today.
              </p>
              <p>
                It’s easy to spend time reading reviews and trying to decide on
                the best services. I certainly did and I would have loved
                something like this early on. You can get set up and online
                today which is a fraction of the time it took me.
              </p>
              <p>
                I use the tools I recommend here myself on this site so if you
                have any questions,{" "}
                <Link to="/about/" style={{ textDecoration: "none", color: "grey" }}>
                get in touch
              </Link>                
                .
              </p>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Introduction;

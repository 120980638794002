import React from "react"
import "../components/web-checklist/styles.css";

// import Layout from "../components/layout"
import SEO from "../components/seo"

// react app imports
import Grid from "@material-ui/core/Grid";
import Introduction from "../components/web-checklist/Introduction";
import VerticalLineStepper from "../components/web-checklist/Content";
// import Header from "./components/Header";
import Outro from "../components/web-checklist/Outro";
import Footer from "../components/web-checklist/Footer";


function ChecklistApp(){

    return(
       <div
      className="App"
      style={{ maxWidth: "900px", border: "auto", margin: "auto", paddingTop: "10px"}}
    >
    <SEO
      title="Website Setup Checklist"
      description="The quickest & easiest way to set up a low cost website with a custom domain. These are the essentials you need to get your site online today."
    />
      <Grid
        container
        direction="row"
        spacing={1}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          {/* <Header /> */}
          <Introduction />
          <VerticalLineStepper />
          <Outro />
          <Footer />
        </Grid>
      </Grid>
    </div>


    )
}

export default ChecklistApp

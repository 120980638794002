import React from "react";
import "./styles.css";
import { Grid } from "@material-ui/core";

import { Link } from "gatsby"

// import TwitterIcon from "@material-ui/icons/Twitter";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import InfoIcon from "@material-ui/icons/Info";

function Footer() {
  return (
    <div
      className="Footer"
      style={{
        maxWidth: "900px",
        border: "auto",
        margin: "auto"
      }}
    >
      <Grid
        container
        direction="row"
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <div
            style={{
              textAlign: "left",
              fontSize: "0.95rem",
              padding: "10px",
              paddingTop: "0px",
              paddingBottom: "0px",
              color: "grey"
            }}
          >
            <p>
              This page contains affiliate links. If you click a product and
              decide to purchase, I may receive a small commission at no extra
              cost to you. This helps me make content and tools like this.
            </p>
            <p>
              Please share this with anyone you think it would help. Give me any
              feedback about the things that you found helpful and anything that
              wasn’t clear. I’m listening and feedback lets me improve this page
              for anyone else.
            </p>
            <p>https://campbelldatascience.com/web-setup-checklist/</p>
            <p>
              This page was built using React.js, a frontend javascript library.
              If you know html, javascript and css; learning React.js is a great
              next step. The{" "}
              <Link to="/" style={{ textDecoration: "none"}}>
              CDS blog
              </Link>       
              {" "}
              is where I write about these topics, as well as helping data
              scientists get into web development.
            </p>

            {/* <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="flex-end"
              item
              xs={8}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                paddingRight: "10px"
              }}
            >
              <TwitterIcon /> <FacebookIcon /> <InfoIcon />
            </Grid> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
